import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useProject } from '../../context/ProjectContext';

const AddProject = () => {
   const [projectObj, setProjectObj] = useState({
      projectNumber: '',
      suburb: '',
      address: '',
      postcode: '',
      state: '',
   });
   const { addProject } = useProject();

   const history = useHistory();

   const handleChange = (e) => {
      setProjectObj({
         ...projectObj,
         [e.target.id]: e.target.value,
      });
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      addProject(projectObj);
      history.push('/');
   };

   return (
      <div>
         <form onSubmit={handleSubmit}>
            <input
               id='projectNumber'
               type='text'
               placeholder='Project No.'
               onChange={handleChange}
            />
            <input
               id='address'
               type='text'
               placeholder='Address'
               onChange={handleChange}
            />
            <input
               id='suburb'
               type='text'
               placeholder='Suburb'
               onChange={handleChange}
            />
            <input
               id='state'
               type='text'
               placeholder='state'
               onChange={handleChange}
            />
            <input
               id='postcode'
               type='text'
               placeholder='Post Code'
               onChange={handleChange}
            />
            <div>
               <button disabled={!projectObj} type='submit'>
                  Save
               </button>
               <button disabled={!projectObj}>Cancel</button>
            </div>
         </form>
      </div>
   );
};

export default AddProject;
