import ProjectList from './ProjectList';

const Dashboard = () => {
   return (
      <div>
         Home Dahsboard
         <ProjectList />
      </div>
   );
};

export default Dashboard;
