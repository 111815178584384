import React from 'react';
import { Phase } from '../../constant/Phase';
import PhaseCard from './PhaseCard';

const BudgetDashboard = () => {
   return (
      <div>
         <div className='m-3'>Project Budget</div>
         <>{Phase && Phase.map((p) => <PhaseCard key={p.id} phase={p} />)}</>
      </div>
   );
};

export default BudgetDashboard;
