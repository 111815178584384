import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useProject } from '../../context/ProjectContext';
const SelectedProject = () => {
   const { selectedProject, getProject } = useProject();
   const [projectId, setProjectId] = useState();

   // const { id } = useParams();

   useEffect(() => {
      const id = localStorage.getItem('projectId');
      getProject(id);
   }, [projectId]);

   return (
      <div>
         {selectedProject && selectedProject ? (
            <>
               Selected Project {projectId} - {selectedProject.projectNumber}{' '}
            </>
         ) : (
            <>Loading</>
         )}
      </div>
   );
};

export default SelectedProject;
