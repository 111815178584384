import { useState } from 'react';
import './App.css';
import Login from './components/account/Login';
import { useAuth } from './context/AuthContext';

import PrivateRoute from './routes/PrivateRoute';

function App() {
   const { currentUser } = useAuth();

   return (
      <div className='container'>
         {currentUser ? <PrivateRoute /> : <Login />}
      </div>
   );
}

export default App;
