import { useDext } from '../../context/DextContext';
import { useInvoice } from '../../context/InvoiceContext';
import DextInvoiceCard from './DextInvoiceCard';

const DextInvoices = () => {
   const { dextInvoices } = useDext();
   console.log('Dext', dextInvoices);

   const compare = (a, b) => {
      const bandA = new Date(a.data.crated_date);
      const bandB = new Date(b.data.crated_date);

      let comparison = 0;
      if (bandA < bandB) {
         comparison = 1;
      } else if (bandA > bandB) {
         comparison = -1;
      }

      return comparison;
   };
   if (dextInvoices) {
      console.log(`Data exist`);
      console.log(`object`, dextInvoices.sort(compare));
   }

   return (
      <>
         {dextInvoices &&
            dextInvoices.map((dext, index) => (
               <DextInvoiceCard key={index} dext={dext} />
            ))}
      </>
   );
};

export default DextInvoices;
