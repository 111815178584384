import { NavLink } from 'react-router-dom';

const ProjectCard = ({ project }) => {
   const {
      id,
      data: { projectNumber, suburb },
   } = project;

   const handleClick = () => {
      localStorage.setItem('projectId', id);
   };

   return (
      <div>
         <div className='d-flex'>
            {projectNumber} - {suburb}
         </div>

         <button onClick={handleClick}>
            <NavLink to={`/project/${id}`}>
               <div>View</div>
            </NavLink>
         </button>
      </div>
   );
};

export default ProjectCard;
