import { faHome, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useInvoice } from '../../context/InvoiceContext';
import { useProject } from '../../context/ProjectContext';
import CostCodeInvoices from '../dext/CostCodeInvoices';
import InvoiceList from './InvoiceList';

const CostCodeCard = ({ costCode, index, stage, phase, projectInvoices }) => {
   const { id, code, title } = costCode;
   const { phaseCode } = phase;
   const { stageCode } = stage;

   console.log(`id`, id);

   console.log(`projectInvoices`, projectInvoices);

   const costCodeInvoices = projectInvoices.filter(
      (i) => parseInt(i.data.costCodeId) === id
   );
   console.log(`costCodeInvoices`, costCodeInvoices);

   return (
      <div>
         <div className='d-flex'>
            <div>
               {index} - {title} <FontAwesomeIcon icon={faPlus} />
            </div>
            <div className='px-2'>
               <div className='fs-6'>
                  {/* (P{phaseCode}.S{stageCode}.{code}) */}
               </div>
            </div>
         </div>
         <div>
            {costCodeInvoices &&
               costCodeInvoices.map((invoice, index) => (
                  <CostCodeInvoices key={index} invoice={invoice} />
               ))}
         </div>
      </div>
   );
};

export default CostCodeCard;
