export const CostCode = [
   // Phase 1 - Lead
   {
      id: 101,
      code: '010',
      title: 'Initial Enquiry',
      displayOrder: 1,
      stageId: 11,
   },
   {
      id: 102,
      code: '01.02.010',
      title: 'Site Investigation',
      displayOrder: 1,
      stageId: 12,
   },
   {
      id: 103,
      code: '01.02.020',
      title: 'Soil & Wind Classification',
      displayOrder: 1,
      stageId: 12,
   },
   {
      id: 104,
      code: '01.02.030',
      title: 'Contour Survey',
      displayOrder: 1,
      stageId: 12,
   },
   {
      id: 105,
      title: 'Concept Design',
      displayOrder: 1,
      stageId: 12,
   },
   {
      id: 106,
      title: '3D Presentation',
      displayOrder: 1,
      stageId: 12,
   },
   {
      id: 107,
      title: 'Tender Presnation',
      displayOrder: 1,
      stageId: 12,
   },

   // Phase 2 - Pre Construction
   {
      id: 201,
      title: 'Plans',
      displayOrder: 1,
      stageId: 21,
   },
   {
      id: 202,
      title: 'Council & Certifier Fee',
      displayOrder: 1,
      stageId: 21,
   },
   {
      id: 202,
      title: 'Sydney Water Approval',
      displayOrder: 1,
      stageId: 21,
   },
   {
      id: 203,
      title: 'BASIX & NaTHERS',
      displayOrder: 1,
      stageId: 21,
   },
   {
      id: 204,
      title: 'Structural Engineeirng',
      displayOrder: 1,
      stageId: 21,
   },
   {
      id: 205,
      title: 'Hydrulic Engineering',
      displayOrder: 1,
      stageId: 21,
   },
   {
      id: 206,
      title: 'Special Reports',
      displayOrder: 1,
      stageId: 21,
   },
   {
      id: 207,
      title: 'Kitchen Consult',
      displayOrder: 1,
      stageId: 22,
   },
   {
      id: 208,
      title: 'Electrical Consult',
      displayOrder: 1,
      stageId: 22,
   },
   {
      id: 209,
      title: 'Colour Consultant',
      displayOrder: 1,
      stageId: 22,
   },
   {
      id: 210,
      title: 'Tile Consultant',
      displayOrder: 1,
      stageId: 22,
   },
   {
      id: 211,
      title: 'Timber & Carpet Consultant',
      displayOrder: 1,
      stageId: 22,
   },

   // Phase 3 - Construction
];
