import { Link, NavLink } from 'react-router-dom';

const ProjectNav = () => {
   return (
      <>
         <div className='d-flex justify-content-center gap-4'>
            <Link
               to='/details'
               className='text-white'
               style={{ textDecoration: 'none' }}
            >
               Details
            </Link>
            <Link
               to='/budget'
               className='text-white'
               style={{ textDecoration: 'none' }}
            >
               Budget
            </Link>

            <Link
               to='/claims'
               className='text-white'
               style={{ textDecoration: 'none' }}
            >
               Claims
            </Link>

            <Link
               to='/orders'
               className='text-white'
               style={{ textDecoration: 'none' }}
            >
               Orders
            </Link>
            <Link
               to='/invoices'
               className='text-white'
               style={{ textDecoration: 'none' }}
            >
               Invoices
            </Link>
         </div>
      </>
   );
};

export default ProjectNav;
