export const Stage = [
   // Phase 1 - Leads
   {
      id: 11,
      stageCode: '1',
      title: 'Enquiry',
      displayOrder: 1.1,
      phaseId: 1,
   },
   {
      id: 12,
      stageCode: 2,
      title: 'Opportunity',
      displayOrder: 1.2,
      phaseId: 1,
   },

   // Phase 2 - Pre Construction
   {
      id: 21,
      stageCode: 1,
      title: 'Approval',
      displayOrder: 2.1,
      phaseId: 2,
   },
   {
      id: 22,
      stageCode: 2,
      title: 'Selection',
      displayOrder: 2.2,
      phaseId: 2,
   },
   // Phase 3 - Construction
   {
      id: 31,
      stageCode: 1,
      title: 'Site Setup',
      displayOrder: 3.1,
      phaseId: 3,
   },
   {
      id: 32,
      stageCode: 2,
      title: 'Slab',
      displayOrder: 3.2,
      phaseId: 3,
   },
   {
      id: 33,
      stageCode: 3,
      title: 'Frame',
      displayOrder: 3.3,
      phaseId: 3,
   },
   {
      id: 34,
      stageCode: 4,
      title: 'Building Envelope',
      displayOrder: 3.4,
      phaseId: 3,
   },
   {
      id: 35,
      stageCode: 5,
      title: 'Lining',
      displayOrder: 3.5,
      phaseId: 3,
   },
   {
      id: 36,
      stageCode: 6,
      title: 'Fix Out',
      displayOrder: 3.6,
      phaseId: 3,
   },
   {
      id: 37,
      stageCode: 7,
      title: 'Practical Completion',
      displayOrder: 3.7,
      phaseId: 3,
   },
   {
      id: 38,
      stageCode: 8,
      title: 'Landscape',
      displayOrder: 3.8,
      phaseId: 3,
   },
   {
      id: 39,
      stageCode: 9,
      title: 'Handover',
      displayOrder: 3.9,
      phaseId: 3,
   },

   // Phase 4 - Warranty
   {
      id: 41,
      stageCode: 1,
      title: 'Warranty - 90 Days',
      displayOrder: 4.1,
      phaseId: 4,
   },
   {
      id: 41,
      stageCode: 2,
      title: 'Warranty - 24 Months',
      displayOrder: 4.2,
      phaseId: 4,
   },
   {
      id: 41,
      stageCode: 3,
      title: 'Structural - 6 years',
      displayOrder: 4.2,
      phaseId: 4,
   },
];
