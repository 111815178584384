import { createContext, useContext } from 'react';
import { db, timeStamp } from '../server/firebase';

const createdAt = timeStamp();
const updatedAt = timeStamp();

const ProductContext = createContext();

export const useProduct = () => useContext(ProductContext);

export const ProductProvider = ({ children }) => {
   const postProduct = (payload) => {
      return db.collection('products').add({
         ...payload,
         createdAt,
      });
   };

   const value = { postProduct };

   return (
      <ProductContext.Provider value={value}>
         {children}
      </ProductContext.Provider>
   );
};
