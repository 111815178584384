import { useProject } from '../../context/ProjectContext';
import ProjectCard from './ProjectCard';

const ProjectList = () => {
   const { projects } = useProject();

   return (
      <div>
         {projects &&
            projects.map((project, index) => (
               <ProjectCard key={index} project={project} />
            ))}
      </div>
   );
};

export default ProjectList;
