import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ProjectProvider } from './context/ProjectContext';
import { ProductProvider } from './context/ProductContext';
import { AuthProvider } from './context/AuthContext';
import { DextProvider } from './context/DextContext';
import { InvoiceProvider } from './context/InvoiceContext';

ReactDOM.render(
   <React.StrictMode>
      <AuthProvider>
         <DextProvider>
            <ProjectProvider>
               <InvoiceProvider>
                  <ProductProvider>
                     <App />
                  </ProductProvider>
               </InvoiceProvider>
            </ProjectProvider>
         </DextProvider>
      </AuthProvider>
   </React.StrictMode>,
   document.getElementById('root')
);
