import { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../server/firebase';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
   const [currentUser, setCurrentUser] = useState();

   const [loading, setLoading] = useState(true);

   const login = async (email, password) => {
      console.log(email, password);
      return await auth.signInWithEmailAndPassword(email, password);
   };

   const logout = () => auth.signOut();

   useEffect(() => {
      const unsubscriber = auth.onAuthStateChanged((user) => {
         setCurrentUser(user);
         setLoading(false);
      });
      return unsubscriber;
   }, []);

   const value = {
      currentUser,
      login,
      logout,
   };

   return (
      <AuthContext.Provider value={value}>
         {!loading && children}
      </AuthContext.Provider>
   );
};
