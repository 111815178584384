import { useEffect, useState } from 'react';
import { CostCode } from '../../constant/CostCode';
import { useInvoice } from '../../context/InvoiceContext';
import { useProject } from '../../context/ProjectContext';
import CostCodeCard from './CostCodeCard';

const StageCard = ({ stage, phase }) => {
   const { id, title, stageCode, displayOrder } = stage;

   const { Invoices } = useInvoice();
   const { selectProject } = useProject();
   const [projectId, setProjectId] = useState(() =>
      localStorage.getItem('projectId')
   );
   const [filteredInvocies, setFilteredInvocies] = useState();

   console.log(`Invoices`, Invoices);

   useEffect(() => {
      setProjectId(localStorage.getItem('projectId'));
   }, [projectId]);

   const projectInvoices = Invoices.filter(
      (i) => i.data.projectId === projectId
   );

   const stageCostCodes = CostCode.filter((cc) => {
      return cc.stageId === id;
   });

   return (
      <div>
         <div>
            Stage {stageCode} - {title}
         </div>
         <div className='mx-5'>
            {stageCostCodes &&
               stageCostCodes.map((cc, index) => (
                  <CostCodeCard
                     key={index}
                     index={index + 1}
                     costCode={cc}
                     stage={stage}
                     phase={phase}
                     projectInvoices={projectInvoices}
                  />
               ))}
         </div>
      </div>
   );
};

export default StageCard;
