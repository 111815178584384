import { createContext, useContext, useEffect, useState } from 'react';
import { db } from '../server/firebase';

const DextContext = createContext();

export const useDext = () => useContext(DextContext);

export const DextProvider = ({ children }) => {
   const [dextInvoices, setDextInvoices] = useState();
   const [dextInvoice, setDextInvoice] = useState();

   const getInvoices = async () => {
      return await db
         .collection('dext_receipt')
         .orderBy('crated_date', 'asc')
         .onSnapshot((snapshot) =>
            setDextInvoices(
               snapshot.docs.map((doc) => ({
                  id: doc.id,
                  data: doc.data(),
               }))
            )
         );
   };
   const getDextInvoice = async (id) => {
      return await db
         .collection('dext_receipt')
         .doc(id)
         .get()
         .then((doc) => {
            if (doc.exists) {
               // console.log('Document data:', doc.data());
               setDextInvoice(doc.data());
            } else {
               // doc.data() will be undefined in this case
               console.log('No such document!');
            }
         })
         .catch((error) => {
            console.log('Error getting document:', error);
         });
   };

   useEffect(() => {
      getInvoices();
   }, []);

   const value = { dextInvoice, dextInvoices, getDextInvoice };
   return <DextContext.Provider value={value}>{children}</DextContext.Provider>;
};
