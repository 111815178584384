import { createContext, useContext, useEffect, useState } from 'react';
import { db, timeStamp } from '../server/firebase';

const createdAt = timeStamp();
const updatedAt = timeStamp();

const InvoiceContext = createContext();

export const useInvoice = () => useContext(InvoiceContext);

export const InvoiceProvider = ({ children }) => {
   const [Invoices, setInvoices] = useState([]);
   const [selectedInvoice, setSelectedInvoice] = useState();

   const getInvoice = async (id) => {
      return await db
         .collection('invoices')
         .doc(id)
         .get()
         .then((doc) => {
            if (doc.exists) {
               // console.log('Document data:', doc.data());
               setSelectedInvoice(doc.data());
            } else {
               // doc.data() will be undefined in this case
               console.log('No such document!');
            }
         })
         .catch((error) => {
            console.log('Error getting document:', error);
         });
   };

   const getInvoices = async () => {
      return await db.collection('invoices').onSnapshot((snapshot) =>
         setInvoices(
            snapshot.docs.map((doc) => ({
               id: doc.id,
               data: doc.data(),
            }))
         )
      );
   };

   const addInvoice = async (payload) => {
      return await db.collection('invoices').add({
         ...payload,
         createdAt,
      });
   };

   //  const editInvoice = async () => {
   //     return await db
   //        .collection('contacts')
   //        .doc(id)
   //        .get()
   //        .then((doc) => {
   //           if (doc.exists) {
   //              // console.log('Document data:', doc.data());
   //              setData(doc.data());
   //           } else {
   //              // doc.data() will be undefined in this case
   //              console.log('No such document!');
   //           }
   //        })
   //        .catch((error) => {
   //           console.log('Error getting document:', error);
   //        });
   //  };

   const deleteInvoice = (id) => {
      return db
         .collection('invoices')
         .doc(id)
         .delete()
         .then(() => {
            console.log('Document successfully deleted!');
         })
         .catch((error) => {
            console.error('Error removing document: ', error);
         });
   };

   // const selectInvoice = (payload) => setSelectedInvoice(payload);

   useEffect(() => {
      getInvoices();
   }, []);

   const value = {
      Invoices,
      selectedInvoice,

      getInvoice,
      addInvoice,
      deleteInvoice,
   };

   return (
      <InvoiceContext.Provider value={value}>
         {children}
      </InvoiceContext.Provider>
   );
};
