import { Stage } from '../../constant/Stage';
import StageCard from './StageCard';

const PhaseCard = ({ phase }) => {
   const { id, title, phaseCode } = phase;

   const phaseStages = Stage.filter((s) => {
      return s.phaseId === id;
   });

   return (
      <div>
         <div className='fw-bold'>
            Phase {phaseCode} - {title}
         </div>
         <div className='mx-3'>
            {phaseStages &&
               phaseStages.map((stage, index) => (
                  <StageCard key={index} stage={stage} phase={phase} />
               ))}
         </div>
      </div>
   );
};

export default PhaseCard;
