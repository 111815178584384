import { NavLink } from 'react-router-dom';

const SetupDashboard = () => {
   return (
      <div>
         <div>
            <NavLink to='/addproject'>Add Project</NavLink>
            <NavLink to='/addcostcode'>Add Cost Code</NavLink>
         </div>
      </div>
   );
};

export default SetupDashboard;
