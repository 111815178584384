export const Phase = [
   {
      id: 1,
      phaseCode: '01',
      title: 'Lead',
   },
   {
      id: 2,
      phaseCode: '02',
      title: 'Pre-Construction',
   },
   {
      id: 3,
      phaseCode: '03',
      title: 'Construction',
   },
   {
      id: 4,
      phaseCode: '04',
      title: 'Warranty',
   },
];
