import { Link, NavLink } from 'react-router-dom';
import { useProject } from '../context/ProjectContext';
import ProjectNav from './ProjectNav';

const NavBar = () => {
   const { selectedProject } = useProject();

   return (
      <>
         <div className='d-flex justify-content-between bg-secondary p-2'>
            <Link
               className='text-white'
               to='/dashboard'
               style={{ textDecoration: 'none' }}
            >
               Dashboard
            </Link>
            {selectedProject ? <ProjectNav /> : <></>}

            <Link
               to='/dext'
               className='text-white'
               style={{ textDecoration: 'none' }}
            >
               Dext
            </Link>
            <Link
               to='/setup'
               className='text-white'
               style={{ textDecoration: 'none' }}
            >
               Setup
            </Link>
         </div>
      </>
   );
};

export default NavBar;
