import { BrowserRouter, Route, Switch } from 'react-router-dom';
import DextInvoiceDetail from '../components/dext/DextInvoiceDetail';
import DextInvoices from '../components/dext/DextInvoices';
import AddProject from '../components/project/AddProject';
import SelectedProject from '../components/project/SelectedProject';
import Dashboard from '../components/home/Dashboard';
import AddCostCode from '../components/costCode/AddCostCode';
import NavBar from '../layout/NavBar';
import ClaimDashboard from '../components/claim/ClaimDashboard';
import InvoicesList from '../components/invoices/InvoicesList';
import SetupDashboard from '../components/setup/SetupDashboard';
import BudgetDashboard from '../components/budget/BudgetDashboard';

const PrivateRoute = () => {
   return (
      <>
         <BrowserRouter>
            <NavBar />
            <Switch>
               <Route exact path='/addproject' component={AddProject} />
               <Route exact path='/dashboard' component={Dashboard} />
               <Route exact path='/project/:id' component={SelectedProject} />
               <Route exact path='/dext' component={DextInvoices} />
               <Route exact path='/dext/:id' component={DextInvoiceDetail} />
               <Route exact path='/setup' component={SetupDashboard} />
               <Route exact path='/addcostcode' component={AddCostCode} />
               <Route exact path='/claims' component={ClaimDashboard} />
               <Route exact path='/invoices' component={InvoicesList} />
               <Route exact path='/budget' component={BudgetDashboard} />
            </Switch>
         </BrowserRouter>
      </>
   );
};

export default PrivateRoute;
