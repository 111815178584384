import { useState } from 'react';
import { FormSelect } from 'react-bootstrap';
import { Phase } from '../../constant/Phase';

const AddCostCode = () => {
   const initialState = {
      title: '',
      code: '',
   };

   console.log(Phase);

   const [costCode, setCostCode] = useState(initialState);
   return (
      <div>
         Cost Code
         <form>
            <input id='title' type='text' placeholder='Cost Code Title...' />
            <FormSelect>
               <option> Select Phase</option>
               {Phase &&
                  Phase.map((p) => <option value={p.id}>{p.title}</option>)}
            </FormSelect>
         </form>
      </div>
   );
};

export default AddCostCode;
