import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';

const DextInvoiceCard = ({ dext }) => {
   const {
      id,
      data: {
         cost_code,
         image,
         invoice_number,
         net_amoun,
         total_amount,
         supplier,
         customer,
         tax_amoun,
         crated_date,
      },
   } = dext;

   return (
      <div className='card m-2 border'>
         <div className='row'>
            <div className='col-lg-6 col-sm-12 m-2'>
               <div>Supplier: {supplier}</div>
               <div>Project: {customer}</div>
               <div>Cost Code: {cost_code}</div>
            </div>
            <div className='col-lg-2 col-sm-2 m-2'>
               <div>
                  Invoice No:
                  <div>{invoice_number}</div>
               </div>
               <div>
                  Created Date:
                  <div>{crated_date}</div>
               </div>
            </div>
            <div className='col-lg-2 col-sm-2 m-2'>
               <div>
                  Total ex. GST:
                  <NumberFormat
                     value={net_amoun}
                     displayType={'text'}
                     thousandSeparator={true}
                     decimalSeparator={'.'}
                     fixedDecimalScale={true}
                     decimalScale={2}
                     allowNegative={true}
                     prefix={'$'}
                  />
               </div>
               <div>
                  GST:
                  <NumberFormat
                     value={tax_amoun}
                     displayType={'text'}
                     thousandSeparator={true}
                     decimalSeparator={'.'}
                     fixedDecimalScale={true}
                     decimalScale={2}
                     allowNegative={true}
                     prefix={'$'}
                  />
               </div>
               <div>
                  Total:
                  <NumberFormat
                     value={total_amount}
                     displayType={'text'}
                     thousandSeparator={true}
                     decimalSeparator={'.'}
                     fixedDecimalScale={true}
                     decimalScale={2}
                     allowNegative={true}
                     prefix={'$'}
                  />
               </div>
            </div>
            <div className='col-lg-2 col-sm-12 m-2'>
               {image ? (
                  <>
                     <div>
                        <a href={image}>View Invoice</a>
                     </div>
                  </>
               ) : (
                  <>No Image</>
               )}
            </div>
            <div>
               <button>
                  <Link to={`/dext/${id}`}>View</Link>
               </button>
            </div>
         </div>
      </div>
   );
};

export default DextInvoiceCard;
