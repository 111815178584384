const AddClaim = () => {
   return (
      <div>
         Add Progress Claim
         <form>
            <input
               id='claimNo'
               type='test'
               placeholder='Progress Claim Title..'
            />
            <input
               id='claimTitle'
               type='test'
               placeholder='Progress Claim Title..'
            />
            <input
               id='claimPercentage'
               type='number'
               placeholder='Progress Claim Precentage..'
            />
            <input
               id='claimAmount'
               type='number'
               placeholder='Progress Claim Amount..'
            />
         </form>
      </div>
   );
};

export default AddClaim;
