import { createContext, useContext, useEffect, useState } from 'react';
import { db, timeStamp } from '../server/firebase';

const createdAt = timeStamp();
const updatedAt = timeStamp();

const ProjectContext = createContext();

export const useProject = () => useContext(ProjectContext);

export const ProjectProvider = ({ children }) => {
   const [projects, setProjects] = useState([]);
   const [selectedProject, setSelectedProject] = useState();

   const getProject = async (id) => {
      return await db
         .collection('projects')
         .doc(id)
         .get()
         .then((doc) => {
            if (doc.exists) {
               // console.log('Document data:', doc.data());
               setSelectedProject(doc.data());
            } else {
               // doc.data() will be undefined in this case
               console.log('No such document!');
            }
         })
         .catch((error) => {
            console.log('Error getting document:', error);
         });
   };

   const getProjects = async () => {
      return await db
         .collection('projects')
         .orderBy('projectNumber', 'asc')
         .onSnapshot((snapshot) =>
            setProjects(
               snapshot.docs.map((doc) => ({
                  id: doc.id,
                  data: doc.data(),
               }))
            )
         );
   };

   const addProject = async (payload) => {
      return await db.collection('projects').add({
         ...payload,
         createdAt,
      });
   };

   //  const editProject = async () => {
   //     return await db
   //        .collection('contacts')
   //        .doc(id)
   //        .get()
   //        .then((doc) => {
   //           if (doc.exists) {
   //              // console.log('Document data:', doc.data());
   //              setData(doc.data());
   //           } else {
   //              // doc.data() will be undefined in this case
   //              console.log('No such document!');
   //           }
   //        })
   //        .catch((error) => {
   //           console.log('Error getting document:', error);
   //        });
   //  };

   const deleteProject = (id) => {
      return db
         .collection('contacts')
         .doc(id)
         .delete()
         .then(() => {
            console.log('Document successfully deleted!');
         })
         .catch((error) => {
            console.error('Error removing document: ', error);
         });
   };

   // const selectProject = (payload) => setSelectedProject(payload);

   useEffect(() => {
      getProjects();
   }, []);

   const value = {
      projects,
      selectedProject,

      getProject,
      addProject,
      deleteProject,
   };

   return (
      <ProjectContext.Provider value={value}>
         {children}
      </ProjectContext.Provider>
   );
};
