import AddClaim from './AddClaim';

const ClaimDashboard = () => {
   return (
      <div>
         Project Claims
         <AddClaim />
      </div>
   );
};

export default ClaimDashboard;
