import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';

const Login = () => {
   const { login } = useAuth();
   const [user, setUser] = useState({
      userName: '',
      password: '',
   });

   console.log(user);

   const handleChange = (e) => {
      setUser({
         [e.target.id]: e.target.value,
      });
   };
   const handleSubmit = (e) => {
      e.preventDefault();
      login('ibi@gmail.com', '111111');
      console.log(user);
   };

   return (
      <>
         Login
         <form onSubmit={handleSubmit}>
            <div className='mb-3'>
               <input
                  type='email'
                  id='userName'
                  placeholder='email...'
                  onChange={handleChange}
               />
            </div>
            <div className='mb-3'>
               <input
                  type='password'
                  id='password'
                  placeholder='password...'
                  onChange={handleChange}
               />
            </div>
            <div className='mb-3'>
               <button type='submit' class='btn btn-primary'>
                  Submit
               </button>
            </div>
         </form>
      </>
   );
};

export default Login;
