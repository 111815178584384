import { useEffect, useState } from 'react';
import { useInvoice } from '../../context/InvoiceContext';
import { useProject } from '../../context/ProjectContext';
import StageInvoicesCard from './StageInvoicesCard';

const InvoicesList = () => {
   const { Invoices } = useInvoice();
   const { selectProject } = useProject();
   const [projectId, setProjectId] = useState(() =>
      localStorage.getItem('projectId')
   );

   useEffect(() => {
      setProjectId(localStorage.getItem('projectId'));
   });

   const projectInvoices = Invoices.filter((i) => {
      return i.data.projectId === projectId;
   });

   console.log('projectInvoices', projectInvoices);

   return (
      <div>
         Project Invoices
         {projectInvoices &&
            projectInvoices.map((invoice) => (
               <>
                  <StageInvoicesCard invoice={invoice} />
                  <div>${invoice.data.dextInvoice.total_amount}</div>
               </>
            ))}
      </div>
   );
};

export default InvoicesList;
