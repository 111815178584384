import { useEffect, useState } from 'react';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useParams } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { useCostCode } from '../../context/CostCodeContext';
import { useDext } from '../../context/DextContext';
import { useProject } from '../../context/ProjectContext';
import { Phase } from '../../constant/Phase';
import { Stage } from '../../constant/Stage';
import { CostCode } from '../../constant/CostCode';
import { useInvoice } from '../../context/InvoiceContext';

const DextInvoiceDetail = () => {
   const { dextInvoice, getDextInvoice } = useDext();
   const { addInvoice } = useInvoice();

   const { projects } = useProject();
   const { id } = useParams();
   const [selectedProject, setSelectedProject] = useState();
   const [selectedPhase, setSelectedPhase] = useState();
   const [selectedStage, setSelectedStage] = useState();
   const [selectedCostCode, setSelectedCostCode] = useState();
   const [projectInvoice, setProjectInvoice] = useState({
      dextInvoice: '',
      phase: '',
      stage: '',
      costCode: '',
   });

   const filteredStages = Stage.filter((s) => {
      return s.phaseId === parseInt(selectedPhase);
   });
   const filteredCostCode = CostCode.filter((c) => {
      return c.stageId === parseInt(selectedStage);
   });

   useEffect(() => {
      getDextInvoice(id);
   }, [id]);

   const handleSubmit = (e) => {
      e.preventDefault();
      addInvoice({
         invoice: dextInvoice,
         projectId: selectedProject,
         phaseId: selectedPhase,
         stateId: selectedStage,
         costCodeId: selectedCostCode,
      });
   };

   console.log('projectInvoice', projectInvoice);

   if (dextInvoice) {
      const {
         customer,
         supplier,
         cost_code,
         image,
         net_amoun,
         tax_amoun,
         total_amount,
         invoice_number,
         crated_date,
      } = dextInvoice;

      return (
         <div>
            <div>
               Invoice Detail
               <div>Supplier: {supplier}</div>
               <div>Project: {customer}</div>
               <div>Cost Code: {cost_code}</div>
               <div>Invoice No.: {invoice_number}</div>
               <div>
                  Total exl. GST
                  <NumberFormat
                     value={net_amoun}
                     displayType={'text'}
                     thousandSeparator={true}
                     decimalSeparator={'.'}
                     fixedDecimalScale={true}
                     decimalScale={2}
                     allowNegative={true}
                     prefix={'$'}
                  />
               </div>
               <div>
                  GST:
                  <NumberFormat
                     value={tax_amoun}
                     displayType={'text'}
                     thousandSeparator={true}
                     decimalSeparator={'.'}
                     fixedDecimalScale={true}
                     decimalScale={2}
                     allowNegative={true}
                     prefix={'$'}
                  />
               </div>
               <div>
                  Total:
                  <NumberFormat
                     value={total_amount}
                     displayType={'text'}
                     thousandSeparator={true}
                     decimalSeparator={'.'}
                     fixedDecimalScale={true}
                     decimalScale={2}
                     allowNegative={true}
                     prefix={'$'}
                  />
               </div>
            </div>
            <div>
               {image ? (
                  <>
                     Image
                     <div>
                        <a href={image}>View Invoice</a>
                     </div>
                  </>
               ) : (
                  <>No Image</>
               )}
            </div>
            <br />
            <div>Assign Project & Cost Code</div>
            <br />
            <form onSubmit={handleSubmit}>
               Select Project
               <Form.Select
                  id='selectedProject'
                  onChange={(e) => setSelectedProject(e.target.value)}
               >
                  {projects &&
                     projects.map((project, index) => (
                        <option key={index} value={project.id}>
                           {project.data.projectNumber} {project.data.suburb}
                        </option>
                     ))}
               </Form.Select>
               {selectedProject ? (
                  <>
                     <Form.Select
                        id='selectedPhase'
                        onChange={(e) => setSelectedPhase(e.target.value)}
                     >
                        {Phase &&
                           Phase.map((phase) => (
                              <option value={phase.id}>{phase.title}</option>
                           ))}
                     </Form.Select>
                  </>
               ) : (
                  <></>
               )}
               {filteredStages ? (
                  <>
                     <Form.Select
                        id='selectedStage'
                        onChange={(e) => setSelectedStage(e.target.value)}
                     >
                        {filteredStages &&
                           filteredStages.map((stage) => (
                              <option value={stage.id}>{stage.title}</option>
                           ))}
                     </Form.Select>
                  </>
               ) : (
                  <></>
               )}
               {filteredCostCode ? (
                  <>
                     <Form.Select
                        id='selectedCostCode'
                        onChange={(e) => setSelectedCostCode(e.target.value)}
                     >
                        {filteredCostCode &&
                           filteredCostCode.map((code) => (
                              <option value={code.id}>{code.title}</option>
                           ))}
                     </Form.Select>
                  </>
               ) : (
                  <div></div>
               )}
               <div>Assign Supplier </div>
               <div>
                  <button
                     type='submit'
                     disabled={selectedStage && selectedStage.value}
                  >
                     Add to Project
                  </button>
               </div>
            </form>
         </div>
      );
   } else return <>Loading</>;
};

export default DextInvoiceDetail;
