import { createContext, useContext, useEffect, useState } from 'react';
import { db, timeStamp } from '../server/firebase';

const createdAt = timeStamp();
const updatedAt = timeStamp();

const CostCodeContext = createContext();

export const useCostCode = () => useContext(CostCodeContext);

export const CostCodeProvider = ({ children }) => {
   const [costCodes, setCostCodes] = useState();

   const addCostCode = async (payload) => {
      return await db.collection('costCodes').add({
         ...payload,
         createdAt,
      });
   };

   const getCostCodes = async () => {
      return await db.collection('costCodes').onSnapshot((snapshot) =>
         setCostCodes(
            snapshot.docs.map((doc) => ({
               id: doc.id,
               data: doc.data(),
            }))
         )
      );
   };

   useEffect(() => {
      getCostCodes();
   }, []);

   const value = { addCostCode, costCodes };
   return (
      <CostCodeContext.Provider value={value}>
         {children}
      </CostCodeContext.Provider>
   );
};
