const CostCodeInvoices = ({ invoice }) => {
   console.log(`ccinvoice`, invoice.data.dextInvoice);
   const { invoice_number, supplier, total_amount } = invoice.data.dextInvoice;
   return (
      <div className='mx-4'>
         {invoice_number} - {supplier} - ${total_amount}
      </div>
   );
};

export default CostCodeInvoices;
